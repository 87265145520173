/** @jsx jsx */
import { jsx } from "theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import SVG from "./Svg";
import { UpDown, UpDownWide } from "../styles/animations";
import ProjectCard from "./ProjectCard";
const Projects = ({
  offset,
  factor = 2,
}: {
  offset: number;
  factor?: number;
}) => (
  <div>
    <Divider
      bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
      speed={-0.2}
      offset={1.1}
      factor={factor}
    />
    <Content speed={0.4} offset={offset + 0.2} factor={factor}>
      <Inner>
        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
            h1: {
              gridColumn: `-1/1`,
              color: `white !important`,
              fontSize: "10vh",
            },
          }}
        >
          <h1>Projects</h1>
          <ProjectCard
            hasLogo={false}
            logo=""
            title="Faibler.com"
            link="https://github.com/ValeriMladenov?tab=repositories&q=Faibler&type=&language="
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            <span role="img" aria-label="Idea,Warning">
              💡⚠️
            </span>
            OpenSource WebApp (COVID-19)
            <br />
            Tech stack: React, Node.js(Express), GraphQL
          </ProjectCard>
          <ProjectCard
            hasLogo={true}
            logo="/images/ecosoft.png"
            title="Ecosoft"
            link="https://ecosoft.bg"
            bg="#00aeef"
          >
            Online Store & Landing pages
            <br /> Tech stack: Laravel, HTML, CSS, JS
          </ProjectCard>
          <ProjectCard
            hasLogo={true}
            logo="/images/bespokeLogo.png"
            title="Bespoke"
            link="https://www.bespoke.bg/btl"
            bg="#ffcc2f"
          >
            Tech stack: PHP, HTML, CSS, JS
          </ProjectCard>
          <ProjectCard
            hasLogo={false}
            logo=""
            title="Notes Clone"
            link="https://github.com/ValeriMladenov?tab=repositories&q=Notes&type=&language="
            bg="#007acc"
          >
            Tech stack: TypeScript, React, Prisma, PostgreSQL
          </ProjectCard>
          <ProjectCard
            hasLogo={true}
            logo="/images/moment.png"
            title="Moment"
            link="https://grabnimoment.bg"
            bg="#000"
          >
            Tech stack: PHP, HTML, CSS, JS <br />
            Design: Henkel Bulgaria
          </ProjectCard>
          <ProjectCard
            hasLogo={true}
            logo="/images/lukoil.png"
            title="Lukoil"
            link="https://club.lukoil.bg/new/"
            bg="#ED1B34"
          >
            Tech stack: HTML, CSS, JS <br />
            Design: Lukoil Bulgaria
          </ProjectCard>
        </div>
      </Inner>
    </Content>
    <Divider speed={0.1} offset={offset} factor={factor}>
      <UpDown>
        <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
        <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <SVG
          icon="triangle"
          width={8}
          stroke
          color="icon_orange"
          left="25%"
          top="5%"
        />
        <SVG
          icon="circle"
          hiddenMobile
          width={24}
          color="icon_brightest"
          left="17%"
          top="60%"
        />
      </UpDown>
      <UpDownWide>
        <SVG
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_green"
          left="20%"
          top="90%"
        />
        <SVG
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="30%"
        />
        <SVG
          icon="circle"
          width={16}
          color="icon_yellow"
          left="70%"
          top="90%"
        />
        <SVG
          icon="triangle"
          hiddenMobile
          width={16}
          stroke
          color="icon_teal"
          left="18%"
          top="75%"
        />
        <SVG
          icon="circle"
          width={6}
          color="icon_brightest"
          left="75%"
          top="10%"
        />
        <SVG
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_green"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <SVG
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
      <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
      <SVG
        icon="hexa"
        width={16}
        stroke
        color="icon_red"
        left="75%"
        top="30%"
      />
      <SVG
        icon="hexa"
        width={8}
        stroke
        color="icon_yellow"
        left="80%"
        top="70%"
      />
    </Divider>
  </div>
);

export default Projects;
